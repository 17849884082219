<template>
  <b-card v-show="invoices">
    <b-overlay
      :show="!invoices"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1 mb-1">
        <div class="ir-top-box-left">
          <b-button
            variant="primary"
            class="ir-btn"
            @click="toCreate"
          >
            Create Invoice
          </b-button>
          <b-button
            v-b-modal.export-modal
            variant="primary"
            class="ml-1 ir-btn"
          >
            Export
          </b-button>
          <b-button
            variant="primary"
            class="ml-1 ir-btn"
            :disabled="zippingInProgress"
            @click="zipInvoicesAndDownload"
          >
            <span v-if="zippingInProgress">
              <b-spinner
                small
                type="grow"
              />
              Zipping...
            </span>
            <span v-else>Zip Invoice/s</span>
          </b-button>
        </div>
        <!-- search input -->
        <div class="custom-search mb-1 ir-top-box-right">
          <b-button
            v-b-modal.search-filter-modal
            variant="outline-primary"
            class="ir-searchFilterbtn"
          >
            Search Filter
          </b-button>
        </div>
        <!-- table -->
        <vue-good-table
          v-if="invoices"
          ref="printMe"
          :columns="columns"
          :rows="invoices"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-5"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :search-options="{
            enabled: true,
            externalQuery: filter.generic
          }"
          :sort-options="{
            enabled: true,
            // initialSortBy: {field: 'customInvoiceNumberForSort', type: 'desc'}
          }"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
        >
          <template slot="loadingContent">
            <div
              class="my-1 d-flex spinner-border text-primary justify-content-center"
              style="width: 2.5rem; height: 2.5rem; margin: auto;"
              role="status"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: invoiceNo -->
            <span
              v-if="props.column.field === 'invoice_number'"
              class="font-weight-bold"
            >
              <router-link
                v-if="props.row.invoice_type === 'custom' && props.row.ed && !props.row.ed.invoice_number_v3"
                :to="`/custom-invoices/${props.row.id}`"
              >
                {{ props.row.invoice_number }}
              </router-link>
              <router-link
                v-else-if="props.row.invoice_type === 'custom' && !props.row.ed"
                :to="`/custom-invoices/${props.row.id}`"
              >
                {{ props.row.invoice_number }}
              </router-link>
              <router-link
                v-else-if="props.row.invoice_type === 'custom_v3' && props.row.ed && props.row.ed.invoice_number_v3"
                :to="`/affiliate-conference-invoice/${props.row.id}/show`"
              >
                {{ 'MI#' + (props.row.ed.invoice_number_v3 + 1000) }}
              </router-link>
              <router-link
                v-else
                :to="`/invoices/${props.row.id}`"
              >
                {{ props.row.invoice_number }}
              </router-link>
            </span>
            <span
              v-else-if="props.column.field === 'reference'"
            >
              <div style="width:170px">{{ props.row.reference ? props.row.reference : '(Payment Recorded/No Trans. Ref.)' }}</div>
            </span>
            <span
              v-else-if="props.column.field === 'deleteInvoice'"
            >
              <div
                v-if="!efacturaInvoices.some(item => item.id === props.row.id)"
                class="btn btn-danger btn-sm"
                @click="deleteInvoice(props.row.id,props.row)"
              >Delete</div>
              <div
                v-else
              >
                <div
                  class="btn btn-info btn-sm"
                  @click="sendToEfactura(props.row)"
                >
                  Resend to e-Factura
                </div><br>
                <b>Index incarcare {{ getIndexIncarcareEfactura(props.row) }}</b>
              </div>
            </span>

            <!-- Column: Client Type -->
            <span
              v-else-if="props.column.field === 'client_type'"
            >
              <div v-if="props.row.invoice_type === 'custom'">
                <b-badge :variant="statusVariant(props.row.uid ? 'Registered' : 'External')">
                  {{ props.row.uid ? 'Registered' : 'External' }}
                </b-badge>
              </div>
              <div v-else-if="props.row.invoice_type === 'custom_v3'">
                <b-badge :variant="statusVariant('External')">
                  External
                </b-badge>
              </div>
              <div v-else>
                <b-badge :variant="statusVariant('Registered')">
                  {{ 'Registered' }}
                </b-badge>
              </div>
            </span>

            <span
              v-else-if="props.column.field === 'due_date'"
            >
              {{ formatDate(props.row.due_date) }}
            </span>

            <span
              v-else-if="props.column.field === 'credit_note'"
              style="font-weight: bold;"
            >
              {{ props.row.credit_note ? props.row.credit_note : 'N/A' }}
            </span>

            <span
              v-else-if="props.column.field === 'issue_date'"
            >
              {{ formatDate(props.row.issue_date) }}
            </span>

            <span
              v-else-if="props.column.field === 'payment_date'"
            >
              {{ formatDate(props.row.payment_date) == 'N/A' ? formatDate(props.row.due_date) : formatDate(props.row.payment_date) }}
            </span>

            <span
              v-else-if="props.column.field === 'totalValue'"
            >
              {{ formatPrice(props.row) }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>

          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','150']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="serverParams.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>

    <b-modal
      id="export-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Export Table"
    >
      <div class="text-center">
        <b-button
          variant="primary"
          class="activation-btn"
          @click="downloadPDF"
        >
          <span v-if="makepdf">
            <b-spinner
              small
              type="grow"
            />
            Creating pdf file...
          </span>
          <span v-else>To PDF</span>
        </b-button><br>
        <b-button
          variant="primary"
          class="activation-btn mt-1 mb-1"
          @click="downloadCSV"
        >
          To Excel
        </b-button>
      </div>
    </b-modal>

    <!-- Search Filter -->
    <b-modal
      id="search-filter-modal"
      ok-title="Apply"
      cancel-title="Reset"
      centered
      title="Search Filter"
      @ok="handleApply"
      @cancel="handleReset"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group
              label="Generic Search"
              label-for="referral-type"
            >
              <b-form-input
                id="GenericSearch"
                v-model="searchTerm"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-form-group
              label="Issue Date:"
              label-for="referral-type"
            >
              <date-range-picker
                v-model="filter.issueDates"
                style="width: 215px;"
              >
                <template
                  style="min-width: 350px;"
                >{{ filter.issueDates.startDate | date }} - {{ filter.issueDates.endDate | date }}</template>
                <!--    footer slot-->
                <div
                  slot="footer"
                  slot-scope="data"
                  class="slot"
                >
                  <div class="custom-search d-flex justify-content-end">
                    <span>Date Range:</span> <span>{{ data.rangeText }}</span>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="data.clickApply"
                    >
                      Ok
                    </b-button>
                  </div>
                </div>
              </date-range-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Due Date:"
              label-for="referral-type"
            >
              <date-range-picker
                v-model="filter.dueDates"
                style="width: 215px;"
              >
                <template
                  style="min-width: 350px;"
                >{{ filter.dueDates.startDate | date }} - {{ filter.dueDates.endDate | date }}</template>
                <!--    footer slot-->
                <div
                  slot="footer"
                  slot-scope="data"
                  class="slot"
                >
                  <div class="custom-search d-flex justify-content-end">
                    <span>Date Range:</span> <span>{{ data.rangeText }}</span>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="data.clickApply"
                    >
                      Ok
                    </b-button>
                  </div>
                </div>
              </date-range-picker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Paid On:"
              label-for="referral-type"
            >
              <date-range-picker
                v-model="filter.paidDates"
                style="width: 218px;"
              >
                <template
                  style="min-width: 350px;width: 350px;"
                >{{ filter.paidDates.startDate | date }} - {{ filter.paidDates.endDate | date }}</template>
                <!--    footer slot-->
                <div
                  slot="footer"
                  slot-scope="data"
                  class="slot"
                >
                  <div class="custom-search d-flex justify-content-end">
                    <span>Date Range:</span> <span>{{ data.rangeText }}</span>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="data.clickApply"
                    >
                      Ok
                    </b-button>
                  </div>
                </div>
              </date-range-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Status:"
              label-for="referral-type"
            >
              <b-form-select
                id="referral-type"
                v-model="filter.status"
                :options="[{ value: 'paid', text: 'Paid' },{ value: 'pending', text: 'Pending' },{ value: 'overdue', text: 'Overdue' }]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row />
        <b-row>
          <b-col>
            <b-form-group
              label="Clients:"
              label-for="referral-type"
            >
              <b-form-select
                id="referral-type"
                v-model="filter.clients"
                :options="[{ value: 'registered', text: 'Registered' },{ value: 'external', text: 'External' }]"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Invoice Type:"
              label-for="referral-type"
            >
              <b-form-select
                id="referral-type"
                v-model="filter.invoiceType"
                :options="[{ value: 'romania', text: 'Romania' }, { value: 'malta', text: 'Malta' },]"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <div style="display:block; visibility:hidden">
      <iframe
        id="pdfScreenShot"
        :src="downloadPDFURL"
        style="display: none;width:0px;height:0px"
        width="0"
        height="0"
      />
    </div>
    <div
      id="divpfdScreenShot"
      style="width:0px; height:0px; margin-top:10px"
    />
    <b-progress
      v-if="showProgress"
      :max="max"
      height="2rem"
      class="zipProgress"
    >
      <b-progress-bar :value="value">
        <span v-if="showProgressStatus">Status: <strong>{{ value }} / {{ max }}</strong></span>
        <span v-else><strong>{{ downloadingText }}</strong></span>
      </b-progress-bar>
    </b-progress>
  </b-card>
</template>

<script>
import {
  BPagination, BFormSelect, BCard, BOverlay, BButton, BBadge, BModal,
  BForm, BFormGroup, BRow, BCol, BSpinner, BFormInput, BProgress, BProgressBar,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import vSelect from 'vue-select'

// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueHtml2Canvas from 'vue-html2canvas'
import jsPDF from 'jspdf'
import DateRangePicker from 'vue2-daterange-picker'
import axios from '@/libs/axios' // eslint-disable-line
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.use(VueHtml2Canvas)

export default {
  components: {
    VueGoodTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    BOverlay,
    BButton,
    BCard,
    BBadge,
    BModal,
    BForm,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    // DatePicker,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    DateRangePicker,
    BSpinner,
    BFormInput,
    BProgress,
    BProgressBar,
  },
  filters: {
    date(date) {
      return new Intl.DateTimeFormat('en-US').format(date)
    },
  },
  data() {
    const startDate = null
    const endDate = null
    return {
      value: 0,
      max: 50,
      showProgress: false,
      showProgressStatus: false,
      downloadingText: 'Downloading.',
      finalZip: false,
      zippingInProgress: false,
      downloadPDFURL: '',
      makepdf: false,
      pageLength: 50,
      // value: [],
      columns: [
        {
          label: 'Invoice No.',
          field: 'invoice_number',
          sortable: true,
        },
        {
          label: 'Credit Note',
          field: 'credit_note',
          sortable: false,
          tdClass: 'font-weight: bold;',
        },

        {
          label: 'Amount',
          field: 'totalValue',
          sortable: true,
        },
        {
          label: 'Client',
          field: 'concat_domain',
          width: '180px',
          sortable: false,
        },
        {
          label: 'Issue Date',
          field: 'issue_date',
          sortable: true,
        },
        {
          label: 'Due Date',
          field: 'due_date',
          sortable: true,
        },
        {
          label: 'Status',
          field: 'paidwith_text',
          sortable: false,
        },
        {
          label: 'Transaction Ref.',
          field: 'reference',
          sortable: false,
        },
        {
          label: 'Paid On',
          field: 'payment_date',
          sortable: true,
        },
        {
          label: 'Client Type',
          field: 'client_type',
          sortable: false,
        },
        {
          label: 'Delete Invoice',
          field: 'deleteInvoice',
          sortable: false,
        },
      ],
      rows: [],
      filter: {
        generic: null,
        status: null,
        clients: null,
        invoiceType: 'romania',
        issueDates: {
          startDate,
          endDate,
        },
        dueDates: {
          startDate,
          endDate,
        },
        paidDates: {
          startDate,
          endDate,
        },
      },
      invoices: [],
      searchTerm: '',
      done: false,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        last30days: 0,
        search: '',
        columnFilters: [{
          column: 'invoice_type',
          filter: 'romania',
        }],
        sort: [
          {
            field: 'invoice_number',
            type: 'desc',
          },
        ],
        page: 1,
        perPage: 50,
      },
      efacturaInvoices: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Registered    : 'light-success',
        External      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    users() {
      return this.$store.state.users.expiredUsers
    },
    invoiceData() {
      return this.$store.state.invoice.invoiceData
    },
    user() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    this.initializedPbarDB()
    const refreshId = setInterval(() => {
      const properID = this.hideExcessTh()
      if (properID === true) {
        clearInterval(refreshId)
      }
    }, 100)
    this.handleReset(null)
  },
  created() {
    // this.fetchInvoices()
    this.loadItems()
  },
  methods: {
    sendToEfactura(invoice) {
      axios.post('/api/invoices/send-to-efactura', {
        invoice_id: invoice.id,
      }).then(() => {
        this.notify({
          text: 'Invoice sent to eFactura',
          variant: 'success',
          timeout: 2000,
        })
        this.loadItems()
      }).catch(() => {
        this.notify({
          text: 'Error sending invoice to eFactura',
          variant: 'danger',
          timeout: 2000,
        })
      })
    },
    getIndexIncarcareEfactura(invoice) {
      const findedInv = this.efacturaInvoices.find(item => item.id === invoice.id)
      const indexEfArr = findedInv.efactura_logs.map(item => {
        if (item.extra_data?.response['@attributes']?.index_incarcare) {
          return item.extra_data.response['@attributes'].index_incarcare
        }
        return null
      })
      return indexEfArr.filter(item => item !== null)[0] || null
    },
    toCreate() {
      this.$router.push('/custom-invoices/create')
    },
    formatPrice(item) {
      // console.log(item.totalValue == null)
      if (item.totalValue == null) return `${item.currency} 0.00`
      if (item.invoice_type === 'custom') return `${item.currency} ${parseFloat(item.totalValue).toFixed(2)}`
      const amount = parseFloat(item.totalValue).toFixed(2)
      return `€ ${amount}`
    },
    formatDate(date) {
      if (date == null) {
        return 'N/A'
      }
      return moment(new Date(date)).format('DD MMM YYYY')
    },
    fetchInvoices() {
      const { last30days } = this.$route.query
      let getLast30days = 0
      if (typeof last30days !== 'undefined') getLast30days = 1

      const payload = {
        last30days: getLast30days,
      }
      this.$store.dispatch('invoice/fetchInvoices', payload)
        .catch(error => { console.log(error) })
    },
    cFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    deleteInvoice(invoice, row) {
      this.confirmationAlert(`Would you like to delete ${row.invoice_number}?`)
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('invoice/deleteInvoice', invoice)
              .then(() => {
                this.loadItems()
                // toast
                this.notify({
                  text: `${row.invoice_number} successfully  deleted`,
                  variant: 'success',
                  timeout: 2000,
                })
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            // toast
            this.notify({
              text: 'Invoice not deleted',
              variant: 'danger',
              timeout: 2000,
            })
          }
        })
    },

    downloadCSV() {
      const header = [
        'Invoice No.',
        'Amount',
        'Client',
        'Issue Date',
        'Due Date',
        'Status',
        'Transaction Ref.',
        'Paid On',
        'Client Type',
      ]

      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.printMe.$refs.table

      const rows = []
      Object.keys(el.rows).forEach(key => {
        if (key >= 1) {
          const row = []
          row.push(`"${el.rows[key].cells[1].textContent.trim()}"`) // Invoice No.
          row.push(`"${el.rows[key].cells[2].textContent.trim()}"`) // Amount
          row.push(`"${el.rows[key].cells[3].textContent.trim()}"`) // Client
          row.push(`"${el.rows[key].cells[4].textContent.trim()}"`) // Issue Date
          row.push(`"${el.rows[key].cells[5].textContent.trim()}"`) // Due Date
          row.push(`"${el.rows[key].cells[6].textContent.trim()}"`) // Status
          row.push(`"${el.rows[key].cells[7].textContent.trim()}"`) // Transaction Ref
          row.push(`"${el.rows[key].cells[8].textContent.trim()}"`) // Paid On
          row.push(`"${el.rows[key].cells[9].textContent.trim()}"`) // Client Type
          rows.push(row)
        }
      })

      rows.unshift(header)
      const jsonObject = JSON.stringify(rows)
      const csv = this.convertToCSV(jsonObject)

      const exportedFilenmae = 'TM-invoiceList.csv'
      const blob = new Blob([`\ufeff${csv}`], { type: 'text/csv; charset=utf-8' })

      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', exportedFilenmae)
      link.style.visibility = 'hidden'
      link.id = 'downloadCsv'
      document.body.appendChild(link)
      document.getElementById('downloadCsv').click()

      // remove to make sure a new csv file will be downloaded and not the previous one
      const element = document.getElementById('downloadCsv')
      element.remove()
    },
    downloadPDF() {
      this.makepdf = true
      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */

      el = this.$refs.printMe.$refs.table
      const options = {
        type: 'dataURL',
      }
      const output = new this.$html2canvas(el, options)
      output.then(ContentCanvas => {
        // get the png invoice header
        /* eslint-disable global-require */
        const headerUrl = require('@/assets/images/banner/invoiceheader.png')
        /* eslint-enable global-require */

        this.getFileFromServer(headerUrl, invoiceDataUrl => {
          /* eslint-disable new-cap */
          const pdf = new jsPDF('p', 'px', 'a4')
          /* eslint-enable new-cap */

          pdf.addImage(invoiceDataUrl, 'png', 0, 0, 440, 100)

          const rowHeight = 20 * (el.rows.length)
          pdf.addImage(ContentCanvas, 'png', -10, 100, 1142 / 2.53, rowHeight)

          // const filename = Math.random().toString(36).substr(2, 5)
          const filename = 'TM-invoiceList'

          // create a new element
          const link = document.createElement('a')

          link.id = 'downloadPdf'
          link.href = pdf.output('datauristring')
          link.download = `${filename}.pdf`

          document.body.appendChild(link)
          document.getElementById('downloadPdf').click()

          // remove to make sure a new pdf will be downloaded and not the previous one
          const element = document.getElementById('downloadPdf')
          element.remove()
          this.makepdf = false
        })
      })
    },

    /*
    * Returns blob
    */
    getFileFromServer(url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.onload = () => {
        const reader = new FileReader()
        reader.onloadend = () => {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    },
    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''
      for (let i = 0; i < array.length; i++) { // eslint-disable-line
        let line = ''
        for (const index in array[i]) { // eslint-disable-line
          if (line !== '') line += ','
          line += array[i][index]
        }
        str += `${line}\r\n` // eslint-disable-line
      }
      return str
    },

    handleApply() {
      this.serverParams.columnFilters = []
      if (this.searchTerm?.length > 0 || this.searchTerm !== null) {
        this.serverParams.search = this.searchTerm
        this.loadItems()
        // this.removeExtraTableHeader()
      } else {
        // disable generic search
        this.$set(this.$refs.printMe.searchOptions, 'enabled', false)

        // Issue date
        if (this.filter.issueDates.startDate !== null) {
          this.serverParams.columnFilters.push({
            column: 'issue',
            start: moment(this.filter.issueDates.startDate).format('YYYY-MM-DD'),
            end: moment(this.filter.issueDates.endDate).format('YYYY-MM-DD'),
          })
        }

        // Due date
        if (this.filter.dueDates.startDate !== null) {
          this.serverParams.columnFilters.push({
            column: 'due',
            start: moment(this.filter.dueDates.startDate).format('YYYY-MM-DD'),
            end: moment(this.filter.dueDates.endDate).format('YYYY-MM-DD'),
          })
        }

        // Paid date
        if (this.filter.paidDates.startDate !== null) {
          this.serverParams.columnFilters.push({
            column: 'payment',
            start: moment(this.filter.paidDates.startDate).format('YYYY-MM-DD'),
            end: moment(this.filter.paidDates.endDate).format('YYYY-MM-DD'),
          })
        }

        // Status
        if (this.filter.status !== null) {
          this.serverParams.columnFilters.push({
            column: 'status',
            filter: this.filter.status,
          })
        }

        // Clients
        if (this.filter.clients !== null) {
          this.serverParams.columnFilters.push({
            column: 'clients',
            filter: this.filter.clients,
          })
        }

        // invoiceType
        if (this.filter.invoiceType !== null) {
          this.serverParams.columnFilters.push({
            column: 'invoice_type',
            filter: this.filter.invoiceType,
          })
        }

        this.loadItems()
        // this.removeExtraTableHeader()
      }
    },
    handleReset(bvModalEvt) {
      if (bvModalEvt) bvModalEvt.preventDefault()

      this.searchTerm = null
      this.serverParams.search = null
      this.serverParams.columnFilters = []
      this.filter.generic = null
      this.filter.status = null
      this.filter.clients = null
      this.filter.invoiceType = 'romania'
      this.filter.issueDates.startDate = null
      this.filter.issueDates.endDate = null
      this.filter.dueDates.startDate = null
      this.filter.dueDates.endDate = null
      this.filter.paidDates.startDate = null
      this.filter.paidDates.endDate = null

      this.loadItems()

      if (bvModalEvt) this.removeExtraTableHeader()
    },

    removeExtraTableHeader() {
      const refreshId = setInterval(() => {
        if (this.$refs.printMe.$refs.table.tHead.rows.length > 1) {
          this.$refs.printMe.$refs.table.tHead.rows[1].hidden = true
          clearInterval(refreshId)
        }
      }, 100)
    },
    filterIssueDate(data, IssueDate) {
      return this.filterDate(data, IssueDate)// eslint-disable-line
    },
    filterDueDate(data, IssueDate) {
      return this.filterDate(data, IssueDate)// eslint-disable-line
    },
    filterPaidDate(data, IssueDate) {
      return this.filterDate(data, IssueDate)// eslint-disable-line
    },
    filterDate(data, IssueDate) {
      if (typeof data === 'undefined') return // eslint-disable-line
      const paramDate = Date.parse(this.filterFormatDate(data))
      const startDate = Date.parse(this.filterFormatDate(IssueDate.startDate))
      const endDate = Date.parse(this.filterFormatDate(IssueDate.endDate))
      return (paramDate >= startDate && paramDate <= endDate)// eslint-disable-line
    },
    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },

    filterStatus(data, status) {
      if (typeof data === 'undefined') return // eslint-disable-line
      return (data === status) // eslint-disable-line
    },
    filterClient(data, client) {
      if (typeof data === 'undefined') return // eslint-disable-line
      else if(data && client==='registered') return true // eslint-disable-line
      else if(!data && client==='registered') return false // eslint-disable-line
      else if(data && client==='external') return false // eslint-disable-line
      else if(!data && client==='external') return true // eslint-disable-line
    },
    filterInvoiceType(data, version) {
      if (typeof data === 'undefined') return // eslint-disable-line
      const dataVerion = Number(data)
      if(dataVerion===1 && version==='malta') return true // eslint-disable-line
      else if(dataVerion===2 && version==='malta') return false // eslint-disable-line
      else if(dataVerion===1  && version==='romania') return false // eslint-disable-line
      else if(dataVerion===2 && version==='romania') return true // eslint-disable-line
    },
    hideExcessTh() {
      if (typeof this.$refs.printMe === 'undefined') return false
      const defaultGlobalSearch = document.getElementsByClassName('vgt-global-search')
      defaultGlobalSearch[0].style.display = 'none'
      return true
    },

    initializedPbarDB() {
      const url = '/api/progress/initializing'
      axios.get(url)
    },

    progressBarUpdate() {
      const url = '/api/progress/get_status'
      const res = axios.get(url)

      res.then(r => {
        const datum = r.data
        this.value = Number(datum.process)
        this.max = Number(datum.total)
      })
    },

    progressBarStatus() {
      this.showProgressStatus = true
      const refreshId = setInterval(() => {
        this.progressBarUpdate()
        if (this.value >= this.max || this.zippingInProgress === false) {
          clearInterval(refreshId)
          this.showProgressStatus = false
          let x = 1
          const refreshId2 = setInterval(() => {
            if (x === 1) this.downloadingText = 'Downloading.'
            if (x === 2) this.downloadingText = 'Downloading..'
            if (x === 3) this.downloadingText = 'Downloading...'
            if (x === 4) this.downloadingText = 'Downloading....'

            x += 1
            if (x >= 5) x = 1

            if (this.showProgress === false) {
              this.value = 0
              clearInterval(refreshId2)
            }
          }, 1000)
        }
      }, 1000)
    },

    zipInvoicesAndDownload() {
      const filteredRows = this.$refs.printMe.filteredRows[0].children
      const rows = this.$refs.printMe.selectedPageRows
      if (filteredRows.length > 100 && rows.length === 0) {
        this.notify({
          text: 'You have selected more than 100 invoices. Please select less than 100 invoices to be zipped.',
          variant: 'danger',
        })
        return
      }
      if (rows.length === 0) {
        this.confirmationAlert('Please select invoice/invoices to be zipped. Or, do you want continue and zip all invoices in the record in one file?')
          .then(res => {
            if (res.isConfirmed) {
              this.zipSelectedInvoices(filteredRows)
            }
          })
      } else {
        this.zipSelectedInvoices(rows)
      }
    },

    async zipSelectedInvoices(rows) {
      // this.progressBarStatus()
      // this.showProgress = true
      // this.zippingInProgress = true
      this.$bvToast.toast('Creating invoices to be zipped. This may take a while. Feel free to browse the website . You will be notified when the process is completed.', {
        title: 'ZIP INVOICES',
        variant: 'primary',
        solid: true,
        autoHideDelay: 10000,
      })

      const rowCount = rows.length
      const payloadArray = []
      for (let i = 0; i < rowCount; i += 1) {
        const item = {
          id: rows[i].id,
          type: rows[i].invoice_type,
        }
        payloadArray.push(item)
      }

      const data = JSON.stringify(payloadArray)
      const payload = { items: data }
      const url = '/api/invoices/zip_all'
      await axios.post(url, payload, { responseType: 'blob' })
        .then(res => {
          if (res?.status === 200) {
            this.$toast.success('Invoices zipped successfully!', {
              position: 'top-center',
              timeout: 10000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
            this.downloadZipFile(res.data)
          } else {
            this.notify({
              text: 'Error zipping invoices.',
              variant: 'danger',
            })
          }
        })
    },

    downloadZipFile(data) {
      const link = document.createElement('a')
      const pdf_url = URL.createObjectURL(data)
      link.setAttribute('href', pdf_url)
      link.setAttribute('download', 'invoices.zip')
      link.style.visibility = 'hidden'
      link.id = 'downloadPDF'
      document.body.appendChild(link)
      document.getElementById(link.id).click()

      // remove to make sure a new csv file will be downloaded and not the previous one
      const element = document.getElementById(link.id)
      element.remove()
      this.initializedPbarDB()
    },

    // vue good table
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1, perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch() {
      this.updateParams({ page: 1, perPage: this.serverParams.perPage, search: this.searchTerm })
      this.loadItems()
    },
    // load items is what brings back the rows from server
    loadItems() {
      const { last30days } = this.$route.query
      this.serverParams.last30days = last30days

      this.isLoading = true
      this.$store.dispatch('invoice/fetchInvoicesServer', this.serverParams)
        .then(response => {
          this.efacturaInvoices = response.data.filter(x => x.efactura_logs.length > 0)
          this.totalRecords = response.total
          this.invoices = this.$store.state.invoice.invoiceData
          this.isLoading = false
          this.done = true
        })
        .catch(error => {
          console.log(error)
        })
    },

  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.calendars {
  color: black;
}

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 55rem !important;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}
.slot {
  padding: 0.5rem;
  justify-content: space-between;
}
.slot span{
  margin-right:5px; margin-top:5px;
  font-size: 11px;
  font-weight: bold;
}

.modal .modal-header .close{
  margin: 2px
}
.filter-th{
  display: none;
}
.vgt-global-search{
  display: none;
}
.vgt-selection-info-row{
  margin-top:50px !important
}
.zipProgress{
  bottom: 0px !important;
  position: fixed;
  width: 80%;
  margin-left: -20px;
}
.vgt-left-align{
  text-transform: capitalize;
}
</style>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
